footer {
  .footer-main {
    background: #06284a;
    text-align: left;
    padding: 40px 0;
    color: #FFF;
  }
  .site-title {
    font-size: 40px;
  }
  ul.menu {
    text-align: left;
    li {
      width: 48%;

      display: inline-block !important;
      &:before {
        content: "-";
        color: #fff !important;
        position: absolute;
        left: 0;
        top: 0;
      }
    }
  }
  ul {
    padding: 0;
    margin: 0;
    text-align: center;
    border-top: none;

    li {
      font-size: 14px;
      list-style-type: none;
      display: block !important;

      text-align: left;
      margin: 0;
      line-height: 30px;
      position: relative;

      a {
        color: #fff !important;
        line-height: 28px;
        transition-duration: .3s;
        padding: 0 3px 0 22px;
        font-size: 14px;
      }
    }
    &.footer-info li {
      content: " ";
    }
  }
}

.footer-bar {
  background: #04203e;
  padding: 15px 0;
  p {
    margin: 0;
    color: #FFF;
    a {
      color: #FFF;
    }
  }
}

footer {
  margin-bottom: 20px;
  a {
    color: #FFF !important;
  }
  p {
    color: #FFF;
  }
}

.widget {
  padding: 0 !important;
}