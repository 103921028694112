
.about-front.inner  .contact-inner {
  margin-bottom: 30px;
}

.about-front.inner  .contact-icon .contact-inner a {
  font-size: 16px;
  display: block;
  color: #737373;
}

.about-front.inner  .contact-icon .contact-inner a i {
  color: #fff;
  display: inline-block;
  font-size: 17px;
  text-align: center;
  line-height: 39px;
  margin-right: 15px;
  border-radius: 3px;
  width: 33px;
  height: 39px;
  border: 1px solid #10b64e;
  background: #10b64e;
  margin-top: 15px;
}

.about-front.inner  .contact-icon .contact-inner a span {
  line-height: 16px;
  display: inline-block;
  overflow: hidden;
}

.about-front.inner  .contact_form .wpcf7 label {
  display: inline-block;
  margin-bottom: .5rem;
  width: 100%;
}

.about-front.inner  .contact_form .wpcf7 .wpcf7-textarea {
  height: 200px;
}

.about-front.inner  .contact_form .wpcf7 p {
  margin-bottom: 0;
}

.about-front.inner  .contact_form .wpcf7 input,
.about-front.inner  .contact_form .wpcf7 .wpcf7-textarea {
  background: #f2f2f2;
}

.about-front.inner  .contact_form .wpcf7 input[type="submit"] {
  display: inline-block;
  font-weight: 400;
  color: #FFF;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  padding: 8px 25px;
  font-size: 15px;
  line-height: 1.5;
  border-radius: 2px;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  position: relative;
  z-index: 9;
  overflow: hidden;
  background-color: #10b64e;
  border: 1px solid #10b64e;
}

.about-front.inner  .contact_form p{
  margin-bottom: 0;
}




/*********************
*    team layout three
*
***********************/
.business_bliss_team .inner-wrapper{
  background: #FFF;
  padding: 50px;
}

/*********************
*    team
*
***********************/
.business_bliss_team {
  width: 100%;
  display: block;
  padding: 0px 0px 60px 0;
}
.business_bliss_team h2 {
  font-family: $font_title;
  font-weight: 300;
  line-height: 1.3em;
  color: #1c4889;
  font-size: 30px;
  transition: all ease 0.3s;
}
.business_bliss_team .box {
  text-align: center;
  margin-bottom: 15px;
  background: #f3f2f2;
  padding-bottom: 10px;
  border: 1px solid #eae8e8;
}

.business_bliss_team .box h4 {
  margin-bottom: 0;
}

.business_bliss_team .box h4 a {
  color: #404040;
  font-weight: 500;
  font-size: 24px;
}

.business_bliss_team .box span {
  text-align: center;
  display: block;
  color: #09286f;
  font-weight: 700;
}

.business_bliss_team .box figure {
  display: block;
  position: relative;
  overflow: hidden;
  margin-bottom: 10px;
}

.business_bliss_team .box figure a.link-icon {
  position: absolute;
  top: 60%;
  z-index: 999;
  width: 100%;
  text-align: center;
  left: 0;
  visibility: hidden;
  font-size: 25px;
  transition: all 0.3s ease 0s;
}

.business_bliss_team .box figure img {
  transition: all 0.4s ease; /* Animation */
}

.business_bliss_team .box figure:hover img {

  transform: scale(1.1); /* CSS3 */
}

.business_bliss_team .box figure:hover a.link-icon {
  top: 45%;
  visibility: visible;
  color:#FFF;
}

.business_bliss_team .box figure:hover .overlay:before {
  bottom: 0;
  opacity: 0.9;
}

.overlay:before {
  background-image: linear-gradient(to left, rgba(0,0,0,1), rgba(0,0,0,0.4));
  bottom: 0;
  height: 100%;
  content: "";
  display: block;
  left: 0;
  opacity: 0;
  position: absolute;

  transition: all 0.3s ease 0s;
  width: 100%;
}

/*--------------------------------------------------*/
.gallery {
  margin-top: 30px;
  .section-title {
    margin-bottom: 30px;
  }
  .gallery-inner {
    margin-bottom: 30px;
    border: 1px solid #eae8e8;
  }
  .box {
    position: relative;
    overflow:hidden;
    height:171px;
    img {
      width: 100%;
    }
    .centerd {
      display: table;
      vertical-align: middle;
      width: 100%;
    }
    .overlay {
      position: absolute;
      bottom: 0;
      top: 0;
      background: rgb(0, 0, 0);
      background: rgba(0, 0, 0, 0.5); /* Black see-through */
      width: 100%;
      transition: .5s ease;
      opacity: 0;
      color: white;
      font-size: 20px;
      padding: 40%;
      text-align: center;
      line-height: 10;
    }
  }

  .album-title h4 {
    font-size: 14px;
    background: #f3f2f2;
    color: #000 !important;
    padding: 10px 15px;
    text-align: center;
    margin:0;
  }

  .gallery-inner:hover .overlay {
    opacity: 1;
  }
  .youtube i {
    color: red;
    font-size: 50px;
    line-height: 4.5;
  }

}

