/* Accessibility
--------------------------------------------- */

/* Text meant only for screen readers. */
.screen-reader-text {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  width: 1px;
  word-wrap: normal !important;

  &:focus {
    background-color: #f1f1f1;
    border-radius: 3px;
    box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
    clip: auto !important;
    clip-path: none;
    color: #21759b;
    display: block;
    font-size: 0.875rem;
    font-weight: 700;
    height: auto;
    left: 5px;
    line-height: normal;
    padding: 15px 23px 14px;
    text-decoration: none;
    top: 5px;
    width: auto;
    z-index: 100000;
  }
}

/* Do not show the outline on the skip link target. */
#primary[tabindex="-1"]:focus {
  outline: 0;
}

/*-----------------------------------------*/

.sub-heading {
  color: #ef2f03;
  font-size: 19px;
  line-height: 1.227em;
  letter-spacing: .06em;
  font-weight: 400;
  font-family: $font_main;
}

.section-title {
  text-align: center;
  background: transparent;
  color: #222222;
  font-size: 35px;
  margin-top: -8px;
  margin-bottom: 40px;
  text-transform: initial;
  letter-spacing: -1.6px;
  padding: 0;
  font-weight: 700;
  line-height: 1;
  position: relative;
  font-family: $font_title;
  @media (max-width: 736px) {
    font-size: 2.6rem;
  }
}

.title-decoration:after {
  content: "";
  position: absolute;
  display: inline-block;
  bottom: -5px;
  width: 100px;
  height: 3px;
  background: #ce4649;
  left: 50%;
  margin-left: -50px;
}

section {
  padding: 60px 0;
}

@media (min-width: 1400px) {
  .container {
    max-width: 1200px;
  }
}

.bg-white {
  background: #FFF;
}