.about-front {
  padding: 0px 0px 60px 0;
  .inner-wrapper {
    background: #FFF;
    padding: 50px;
  }
  .row {
    align-items: center;
  }
  h2 {
    font-family: $font_title;
    font-weight: 300;
    line-height: 1.3em;
    color: #1c4889;
    font-size: 40px;
    transition: all ease 0.3s;
    &:hover {
      letter-spacing: 1px;
    }
  }

  p {
    margin-bottom: 30px;
  }

  &.inner {
    .row {
      align-items: inherit;
    }
    img {
      width: 100%;

    }
  }
}

.blog-main {
  padding: 0px 0px 60px 0;
  .inner-wrapper {
    background: #FFF;
    padding: 50px;
  }

  h2 {
    font-family: $font_title;

    font-weight: 300;
    line-height: 1.3em;
    color: #1c4889;
    font-size: 30px;
    -webkit-transition: all ease 0.3s;
    transition: all ease 0.3s;
  }

  .blog-bottom {
    position: relative;
    padding: 0 10px;
    margin-bottom: -50px;
  }

  .box {
    margin-bottom: 30px;

  }

  .blog-inner {
    position: relative;
    top: -35px;
    z-index: 33;
    background: #fff;
    padding: 15px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.35);
    margin-bottom: -32px;
    transition: all 0.3s ease;
  }

  .blog-inner h3 {
    line-height: 26px;
    position: relative;
    font-size: 20px;
    margin-bottom: 10px;
  }

  .blog-inner h3 a {
    color: #333;
    cursor: pointer;
    text-transform: inherit;
    font-weight: 600;
  }
  .blog-head {
    max-height: 209px;
    border: 2px solid #bbbaba;
    overflow: hidden;

    img {
      transition: all ease 0.3s;
    }
    &:hover {
      transition: all ease 0.3s;
      img {
        transform: scale(1.2);
      }
    }
  }
  .meta {
    border-top: 1px solid #e7e7e7;
    padding-top: 10px;
    margin-top: 10px;
  }

  .meta span {
    display: inline-block;
    margin-right: 10px;
  }

  .meta span a {
    color: #666;
  }

  .meta span i {
    margin-right: 5px;
    color: #10b64e;
  }

  .box:hover .blog-inner {
    top: -40px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.35);
  }

  .owl-carousel .owl-nav button.owl-next, .owl-carousel .owl-nav button.owl-prev {
    position: absolute;
    top: 40%;
    transition: all 0.5s ease;
  }

  .owl-carousel .owl-nav button.owl-next,
  .owl-carousel .owl-nav button.owl-prev {
    color: #000;
    font-size: 14px;
    margin: 5px;
    padding: 9px 20px !important;
    background: #fff;
    box-shadow: -4px 2px 20px 0px rgba(0, 0, 0, 0.75);
    transition: all .4s ease 0s;
    display: inline-block;
    cursor: pointer;
    border-radius: 3px;
    opacity: 1;
    outline: 0;
  }

  .owl-carousel .owl-nav button.owl-next {
    right: -28px;
  }

  .owl-carousel .owl-nav button.owl-prev {
    left: -28px;
  }
}

.img-background-wrapper {
  background-color: #DDFFF7;
  position: relative;
  box-shadow: 10px 10px 42px 0 rgba(0, 0, 0, 0.75);

  img {
    z-index: 9;
    position: relative;
    transition: all ease 0.3s;
  }
  /*&:hover {
    transition:all ease 0.3s;
    img{
      transform: scale(1.1);
    }
  }*/
}

.img-background-wrapper:after, .img-background-wrapper:before {
  mix-blend-mode: multiply;
  filter: none;
  z-index: 1;
  content: '';
  width: calc(100% + (50px * 2));
  height: calc(100% + (50px * 2));
  position: absolute;
  display: block;
  animation: border 10s ease-in-out infinite;
  transform: translateX(-50px) translateY(-50px);
  top: 0;
}

@keyframes border {
  0%, 100% {
    -webkit-clip-path: polygon(0 0, calc(100% - (33.3333333333px)) calc(0% + (33.3333333333px)), 100% 100%, calc(0% + (33.3333333333px)) calc(100% - (33.3333333333px)));
    clip-path: polygon(0 0, calc(100% - (33.3333333333px)) calc(0% + (33.3333333333px)), 100% 100%, calc(0% + (33.3333333333px)) calc(100% - (33.3333333333px)));
  }
  50% {
    -webkit-clip-path: polygon(calc(0% + (33.3333333333px)) calc(0% + (33.3333333333px)), 100% 0, calc(100% - (33.3333333333px)) calc(100% - (33.3333333333px)), 0 100%);
    clip-path: polygon(calc(0% + (33.3333333333px)) calc(0% + (33.3333333333px)), 100% 0, calc(100% - (33.3333333333px)) calc(100% - (33.3333333333px)), 0 100%);
  }
}

.img-background-wrapper:after {
  animation-delay: -5s;
  background-color: #93e1d8;
  clip-path: polygon(0 0, calc(100% - (33.3333333333px)) calc(0% + (33.3333333333px)), 100% 100%, calc(0% + (33.3333333333px)) calc(100% - (33.3333333333px)));
}

.img-background-wrapper:before {
  background-color: #AA4465;
  clip-path: polygon(calc(0% + (33.3333333333px)) calc(0% + (33.3333333333px)), 100% 0, calc(100% - (33.3333333333px)) calc(100% - (33.3333333333px)), 0 100%);
}

/*-------------------------------------------------------------*/
.main-content {
  margin-top: 10px;

  .container.content-wrapper {
    padding-top: 15px;
    padding-bottom: 15px;
    box-shadow: 0 0 15px #acacac;
  }
  .content-wrapper.inner-page {
    background: #f9f9f9;
  }
}

.banner-slider {
  position: relative;
  padding: 0;
  top: -70px;
  z-index: 0;
  overflow: hidden;

  @media (max-width: 1600px) {

    max-height: 600px;
  }
  @media (max-width: 768px) {

    max-height: 500px;
  }
  .slider-caption {
    position: absolute;
    background: transparent;
    max-width: 600px;
    top: 34%;
    z-index: 99;
    margin-left: 86px;

    img {
      max-width: 200px;
    }
    p {
      font-family: 'EB Garamond', serif;
      font-size: 36px;
      line-height: 1;
      color: #FFF;
      text-align: left;
      text-shadow: 0px 2px 3px #252525;
      font-weight: 500;
    }
  }
  .overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 3;
    text-align: left;
    width: 100%;
    height: 100%;
    background: url("../images/banner-overlay.png");
    background-size: cover;
    line-height: 1;
    img {
      height: 100%;
      width: auto;
    }
  }
  .owl-carousel .owl-nav button.owl-next, .owl-carousel .owl-nav button.owl-prev {
    position: absolute;
    top: 36%;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 99999;
    font-size: 20px;
    color: #FFF;
    width: 70px;
    background: #0a63e8;
    outline: 0;
    height: 70px;
    border-radius: 0;
  }
  .owl-carousel .owl-nav button.owl-prev {
    left: 0px;
  }

  .owl-carousel .owl-nav button.owl-next {
    right: 0px;
  }

  .owl-dots {
    position: absolute;
    bottom: 10px;
    opacity: 1;
    transition: all 0.5s ease;
    outline: 0;
    left: 0;
    right: 0;
  }

}

.vip-wrapper {
  overflow-y: scroll;
  max-height: 598px;
  background: #FFF;
  width: 25%;
  padding: 0 15px;
  @media (max-width: 1400px) {
    width: 28%;
    max-height: 474px;
  }
  @media (max-width: 768px) {
    width: 100%;
    max-height: 450px;
  }

}

.vip-person {
  display: flex;
  justify-content: space-between;
  background: #FFF;
  padding: 10px;
  margin-bottom: 15px;
  box-shadow: 2px 0px 18px -9px rgba(0, 0, 0, 0.75);
  align-items: center;
  @media (max-width: 1400px) {
    margin-bottom: 13px;
  }
  img {
    width: 30%;
    overflow: hidden;
    border: 1px solid #005884;
    margin-right: 10px;
    @media (max-width: 1400px) {
      width: 25%;
      max-height: 450px;
    }
  }
  .description {
    width: 75%;
    h3 {
      font-size: 15px;
      @media (max-width: 1400px) {
        padding: 0;
        line-height: 1;
      }
    }
    h4 {
      font-size: 13px;
      @media (max-width: 1400px) {
        padding: 0;
        margin: 0;
      }
    }
    a {
      color: #222222;
    }
  }
}

/*----------------------------*/
.mof-tabs {
  background: #FFF;

  .tab-pane {
    border: 1px solid #e0e0e0;
    background: #FFF;
    padding: 10px;
  }
  .nav-tabs {
    border-bottom: none;
    background: #efefef;
  }
  .nav-tabs .nav-link {
    outline: 0;
    color: #222222;
    &:hover {
      color: #005884;
      border-top: 4px solid #005884;
    }
  }
  .nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff;
    border-top: 4px solid #005884;
    border-bottom: 1px solid #e0e0e0;
  }

  ul {
    padding: 0;
    margin: 0;
    li {
      list-style-type: none;
      border-bottom: 1px solid #ddd;
      margin-bottom: 5px;
      padding: 5px 0;
      figure {
        width: 50px;
        min-width: 50px;
        max-width: 50px;
        background: #005884;
        margin-right: 5px;
        border-radius: 5px;
      }
      h4 {
        margin-bottom: 0;
        @media (max-width: 1400px) {
          font-size: 14px;
        }
      }
      p {
        margin-bottom: 0;
        font-size: 12px;
      }
      .list-content-wrapper {
        display: flex;
        align-items: flex-start;
      }
      &:last-child {
        border-bottom: none;
        margin-bottom: 0px;
        padding: 5px 0;
      }

      a {
        color: #000;
        font-size: 15px;
      }
    }
  }
}

ul.sidebar-list {
  overflow-y: scroll;
  max-height: 500px;
  padding: 0;
  margin: 0;
  li {
    list-style-type: none;
    margin-bottom: 5px;
    padding: 3px 12px;
    background: #005884;
    &:hover {
      background: #077ab3;
      transition: all ease 0.2s;
    }
    figure {
      color: #FFF;
      font-size: 28px;
    }
    h4 {
      margin-bottom: 0;
      color: #FFF !important;
    }
    p {
      margin-bottom: 0;
      font-size: 12px;
      color: #FFF;
    }
    .list-content-wrapper {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
    }
    &:last-child {
      border-bottom: none;
      margin-bottom: 0px;
    }

    a {
      color: #000;
      font-size: 15px;
    }
  }

}

ul.highlight-list {
  padding: 0;
  margin: 0;
  li {
    list-style-type: none;
    border-bottom: 1px solid #cecece;

    a {
      color: #222222;
      font-size: 14px !important;

      td {
        font-size: 14px !important;
      }
    }

    figure {
      width: 50px;
      min-width: 50px;
      max-width: 50px;
      background: #005884;
      margin-right: 5px;
      border-radius: 5px;
    }
  }
  table {
    margin: 0;
  }
}

.card-header {
  border-left: none;
  border-top: none;
  border-right: none;
  border-bottom: 2px solid #005884;
  background: #efefef;
  font-size: 18px;
  font-weight: 500;
}

.card {
  border: none;
  border-radius: 0.25rem;
}

.card-body {
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.card-footer {
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-top: none;
  .page-link {
    background-color: #d01c1f;
    color: #FFF;

  }
}

.content-box {
  background: #FFF;
  .content-title {
    border-bottom: 1px solid #005884;
    padding: 0.75rem 1.25rem;
    background: #efefef;
  }
  .content-description {
    padding: 1.2rem 1.25rem;
    border: 1px solid #e0e0e0;
  }

  .it-officer {
    display: flex;
    figure {
      margin-right: 20px;
      width: 35%;
      img {
        width: 100%;
        border: 1px solid #005884;
      }
    }
    p {
      text-align: left;
    }
  }
  .joint-secretary {
    display: flex;
    figure {
      margin-right: 20px;
      width: 35%;
      img {
        width: 100%;
        border: 1px solid #005884;
      }
    }
    h3 {
      font-size: 13px;
      @media (max-width: 1400px) {
        margin-top: 0;
      }

    }
    p {
      text-align: left;
    }
  }
}

.youtube-video-gallery.owl-carousel {

  .owl-nav button.owl-next, .owl-nav button.owl-prev {
    position: absolute;
    top: 45%;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 99999;
    font-size: 20px;
    color: #FFF;
    width: 30px;
    background: #000;
    border-radius: 50%;
    outline: 0;
  }
  .owl-nav button.owl-prev {
    left: 10px;
  }

  .owl-nav button.owl-next {
    right: 10px;
  }

  .owl-dots {
    position: absolute;
    bottom: 10px;
    opacity: 1;
    transition: all 0.5s ease;
    outline: 0;
    left: 0;
    right: 0;
  }
}

.donate-section {
  position: relative;
  overflow: hidden;

  .description {

  }
  h2 {
    color: #FFF;
    font-family: "Open Sans", sans-serif;
    font-weight: 300;
    line-height: 1.3em;
    font-size: 40px;
    text-align: center;
    transition: all ease 0.3s;
    margin-bottom: 40px;
  }

  p {
    color: #FFF;
    font-size: 20px;
  }
}