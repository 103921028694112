.body-wrapper {
  transition: all .5s ease;
  &.active {
    margin-left: 250px;

  }
}


header {

  display: flex;
  align-items: center;
  justify-content: space-between;
  background: url(../images/header-bg.png) repeat left top;
  z-index: 999999;
  position: relative;
  transition:  all ease 0.3s;
  .logo {
    width: 60px;
    float: none;
    display: none;
    position: relative;
    overflow: hidden;
  }
  .sidmenu-btn {
    color: #FFF;
  }
  .button-right {
    ul {
      text-align: right;
      margin: 0;
      padding: 0;
      li {
        list-style-type: none;
        display: inline-block;
        margin-left: -3px;
        color: #FFF;
        text-align: center;
        font-size: 21px;
        &:last-child {
          width: auto;
          font-size: 22px;
          @media (max-width: 880px) {
            display: none;
          }
        }

        a {
          padding: 21px 29px;
          color: #FFF;
          line-height: 3;
          transition: all ease 0.3s;
          border-left: 1px solid #FFF;
          &.donate {
            background: #8dd816;
          }
          &.facebook:hover {
            background: #3D5A8D;
          }
          &.instagram:hover {
            background: #da1277;

          }
          &.youtube:hover {
            background: #d43418;
          }
        }
      }

    }
  }

  .menu-button {
    text-transform: uppercase;
    position: relative;
    left: 0;
    text-indent: 0;
    font-family: "brandon_grotesqueregular", sans-serif;
    font-size: 17px;
    font-weight: normal;
    font-style: normal;
    line-height: 70px;
    margin-left: 50px;
    padding-left: 22px;
    background-color: transparent;
  }

  &.inner-page {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #FFF;
    z-index: 9999;
    border-bottom: 1px solid #f3f3f3;
    position: relative;
    .button-right {
      ul {
        text-align: right;
        margin: 0;
        padding: 0;
        li {
          list-style-type: none;
          display: inline-block;
          margin-left: -3px;
          color: #FFF;
          text-align: center;
          font-size: 21px;
          &:last-child {
            width: auto;
            font-size: 22px;
            @media (max-width: 880px) {
              display: none;
            }
          }

          a {
            padding: 21px 29px;
            color: #FFF;
            line-height: 3;
            transition: all ease 0.3s;
            border-left: 1px solid #F0F0F1;
            &.donate {
              background: #8dd816;
              color: #FFF;
            }
            &.facebook {
              color: #3D5A8D;
              &:hover {
                background: #3D5A8D;
                color: #FFF;
              }
            }
            &.instagram {
              color: #da1277;
              &:hover {
                background: #da1277;
                color: #FFF;
              }
            }
            &.youtube {
              color: #d43418;
              &:hover {
                background: #d43418;
                color: #FFF;
              }
            }
          }
        }

      }
    }
    .menu-button {

      a {
        color: #1c4889;
      }

    }
  }
  &.inner-page-again {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #FFF;
    z-index: 9999;
    border-bottom: 1px solid #f3f3f3;
    position: relative;
    margin-bottom: 40px;
    .button-right {
      ul {
        text-align: right;
        margin: 0;
        padding: 0;
        li {
          list-style-type: none;
          display: inline-block;
          margin-left: -3px;
          color: #FFF;
          text-align: center;
          font-size: 21px;
          &:last-child {
            width: auto;
            font-size: 22px;
            @media (max-width: 880px) {
              display: none;
            }
          }

          a {
            padding: 21px 29px;
            color: #FFF;
            line-height: 3;
            transition: all ease 0.3s;
            border-left: 1px solid #F0F0F1;
            &.donate {
              background: #84ce23;
              color: #FFF;
            }
            &.facebook {
              color: #3D5A8D;
              &:hover {
                background: #3D5A8D;
                color: #FFF;
              }
            }
            &.instagram {
              color: #da1277;
              &:hover {
                background: #da1277;
                color: #FFF;
              }
            }
            &.youtube {
              color: #d43418;
              &:hover {
                background: #d43418;
                color: #FFF;
              }
            }
          }
        }

      }
    }
    .menu-button {

      a {
        color: #1c4889;
      }

    }
  }
}


.fixed {
  position: fixed !important;
  top: 0;
  left: 0;
  width: 100%;
  transition:  all ease 0.3s;
  .logo{
    display: block;
    @media (max-width: 880px) {
      display: none;
    }
  }

}

.side-title {
  padding-left: 20px;
}

.sidemenu {
  &.open {
    left: 0
  }
  position: fixed;
  left: -250px;
  width: 250px;
  height: 100%;
  background: #042331;
  transition: all .5s ease;
  z-index: 9999;
  header {
    font-size: 22px;
    color: #fff;
    line-height: 32px;
    text-align: center;
    background: #063146;
    user-select: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;

  }

  .sidemenu-close {
    color: #FFF;
    outline: 0;
  }
  ul {
    margin: 0;
    padding: 0;
    li {
      display: list-item;
      border-bottom: 1px solid #000;
      border-top: 1px solid rgba(255, 255, 255, .1);
      a {
        display: block;
        height: 100%;
        width: 100%;
        line-height: 45px;
        font-size: 18px;
        color: #fff;
        padding-left: 25px;
        box-sizing: border-box;

        transition: .4s;
        &:hover {
          background: #021a25;
          letter-spacing: 1px;
        }
      }
    }
  }
}

ul.sub-menu {

  li:last-child {
    border-bottom: none;
    border-top: 1px solid rgba(255, 255, 255, .1);
  }
  a {
    padding-left: 50px !important;
    font-size: 14px !important;

  }
}

