/* Links
--------------------------------------------- */
a {
  color: #222222;
}

a:visited {
  color: #222222;
}

a:hover,
a:focus,
a:active {
  color: #222222;
  text-decoration: none;
}

a:focus {
  outline: thin dotted;
}

a:hover,
a:active {
  outline: 0;
}
.btn {
  font-weight: 500;
  font-size: 12px;
  border-radius: 3px;
  border: 1px solid transparent;
}
.btn.btn-primary {
  background-color: #d01c1f;
  border: none;
  color: #fff !important;
  transition: all .3s ease;
}

.btn.btn-default {
  background-color: #fafafa;
  color: #666;
  border-color: #ddd;
  transition: all .3s ease;
}


.btn_link{
  background: #ef2f03;
  color: #FFF !important;
  padding: 9px 20px;
  transition: all ease 0.3s;
  &:hover{
    background:#205cb5 !important;
  }
}