/*--------------------------------------------------------------
## Menus
--------------------------------------------------------------*/
span.sub-toggle {
  display: none;
  position: absolute;
  top: 0px;
  right: 2px;
  width: 42px;
  height: 42px;
  text-align: center;
  background: transparent;
  color: #FFF;
  line-height: 42px;
  z-index: 99;
  cursor: pointer;
}

.main-menu-bg {
  background: #0077b5;
}

.main-navigation {
  vertical-align: middle;
  width: 100%;
  position: relative;
}

.main-navigation ul {
  margin: 0;
  padding: 0px;
}


.main-navigation ul.nav-menu {
  @media (max-width: 768px) {
    text-align: center;
    margin: 0px;
  }
}

.main-navigation ul li {
  display: inline-block;
  vertical-align: top;
  position: relative;
  margin: 0px;
  text-align: left;
  padding: 0;
}

.main-navigation ul ul li {
  margin: 0;
  padding: 0;
}

.main-navigation ul li a {
  display: block;
  color: #222222;
  font-size: 13px;
  transition: all 0.3s ease;
  font-family: $font_main;
  font-weight: 400;
}

.main-navigation ul.nav-menu > {
  background: transparent;
  text-align: right;
  padding: 30px 0;

}
.main-navigation ul.nav-menu >  li{

}
.main-navigation ul.nav-menu >  li > a {
  padding: 5px 16px;
  transition: all 0.3s;
  position: relative;
  font-size: 14px;
  font-weight: 500;
  color: #FFF;
}


.main-navigation ul li:hover > a,
.main-navigation ul li.current-menu-item > a,
.main-navigation ul li.active > a {
  color: #FFF;
  transition: all 0.3s;
}

.main-navigation ul li ul {
  left: 0;
  top: 75%;
  margin: 0;
  width: 240px;
  display: block;
  list-style: none;
  position: absolute;
  visibility: hidden;
  opacity: 0;
  background-color: #c6131d;
  z-index: 999;
  transform: translate(0, 10px);
  transition: all .3s ease-in-out;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.3);
  padding: 0;
}
@media (min-width: 800px) {
  .main-navigation ul li ul.sub-menu-mega {
    width: 900px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .main-navigation ul ul.sub-menu-mega > li {
    width: 24%;
  }
}
.main-navigation ul li ul li {
  position: relative;
  display: block;
  border-bottom: 1px solid #e0e0e0;
}

.main-navigation ul li ul li a {
  padding: 7px 10px;
  color: #FFF;
  font-size: 14px;
  font-weight: 500;
}

.main-navigation ul li ul li a:hover {
  color: #FFF;
  background: #077ab3;
}

.main-navigation ul li:hover > ul {
  opacity: 1;
  visibility: visible;
}

.main-navigation ul li ul li ul {
  left: 100%;
  top: 0%;
  margin: 0;
  width: 215px;
  display: block;
  list-style: none;
  position: absolute;
  visibility: hidden;
  opacity: 0;
  background-color: #c6131d;
  z-index: 1;
  padding: 0;
  transform: translate(0, 10px);
  transition: all .3s ease-in-out;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.3);
}

.main-navigation ul li ul li:hover > ul {
  transform: translate(0, 0);
  opacity: 1;
  visibility: visible;
}

/*menu has children*/
.main-navigation ul > li.menu-item-has-children {
  position: relative;
}

.main-navigation ul > li.menu-item-has-children > a {
  padding-right: 25px;
}

.main-navigation div > ul > li.menu-item-has-children:before {
  content: '\f0d7';
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-family: "Font Awesome 5 Free";
  font-weight: 700;
  color: #FFF;
  right: 11px;
  font-size: 14px;
}

.main-navigation ul ul li.menu-item-has-children:before {
  content: '\f0da';
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  font-family: "Font Awesome 5 Free";
  font-weight: 700;
  color: $color_white;
  right: 9px;
}

.main-navigation div > ul > li.menu-item-has-children:hover:before {
  color: #FFF;
}

.main-navigation ul ul li.menu-item-has-children:before {
  color: #FFF;
}

.main-navigation ul ul li.menu-item-has-children:hover:before {
  color: #FFFFFF;
}

.site-main .comment-navigation, .site-main
.posts-navigation, .site-main
.post-navigation {
  margin: 0 0 1.5em;
  overflow: hidden;
}

.comment-navigation .nav-previous,
.posts-navigation .nav-previous,
.post-navigation .nav-previous {
  float: left;
  width: 50%;
}

.comment-navigation .nav-next,
.posts-navigation .nav-next,
.post-navigation .nav-next {
  float: right;
  text-align: right;
  width: 50%;
}

.main-menu-toggle {
  display: none;
}
