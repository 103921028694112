/* Typography
--------------------------------------------- */
body,
button,
input,
select,
optgroup,
textarea {
  color: #222;
  font-family: $font_text;
  /*font-family: 'Open Sans', sans-serif;*/
  font-size: 16px;
}

h1, h2, h3, h4, h5, h6 {
  font-family: $font_text;
  /*font-family: 'Open Sans', sans-serif;*/
}

h1,
h2,
h3,
h4,
h5,
h6 {
  clear: both;
  font-family: $font_title;
  font-weight: 400;
  line-height: 1.42857143;
}


h3 {
  padding-top: 15px;
  margin-bottom: 0;
  font-size: 18px;
  font-weight: 700;
}

.table th, .table td {
  font-size: 14px;
  font-family: $font_text;
  color: $color_text_para;
}
 h4, h5 {
  color:#000 !important;
}
p {
  margin: 0 0 10px;

  font-size: 16px;
  line-height: 1.4em;
  color: #4e4e4e;
  font-family: 'Open Sans', sans-serif;

}

dfn,
cite,
em,
i {
  font-style: italic;
}

blockquote {
  margin: 0 1.5em;
}

address {
  margin: 0 0 1.5em;
}

pre {
  background: #eee;
  font-family: "Courier 10 Pitch", courier, monospace;
  line-height: 1.6;
  margin-bottom: 1.6em;
  max-width: 100%;
  overflow: auto;
  padding: 1.6em;
}

code,
kbd,
tt,
var {
  font-family: monaco, consolas, "Andale Mono", "DejaVu Sans Mono", monospace;
}

abbr,
acronym {
  border-bottom: 1px dotted #666;
  cursor: help;
}

mark,
ins {
  background: #fff9c0;
  text-decoration: none;
}

big {
  font-size: 125%;
}