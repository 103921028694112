/*--------------------------------------------------------------
# Forms
--------------------------------------------------------------*/
.col-form-label {
  font-size: 14px;
}

::placeholder {
  font-family: Montserrat, sans-serif;
  font-size: 12px;
  line-height: 20px;
}

input[type="text"], input[type="email"], input[type="url"], input[type="password"], input[type="search"], input[type="number"], input[type="tel"], input[type="range"], input[type="date"], input[type="month"], input[type="week"], input[type="time"], input[type="datetime"], input[type="datetime-local"], input[type="color"], textarea {
  width: 100%;
  padding: 10px;
  margin: 0 0 15px;
  font-family: Montserrat, sans-serif;
  font-size: 12px;
  line-height: 20px;
  font-weight: inherit;
  color: #999;
  background-color: #FFF;
  border-radius: 3px;
  outline: 0;
  box-sizing: border-box;
  transition: all ease 0.4s;
  border: 1px solid #eaeaea;

}

/*input[type="text"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="password"]:focus,
input[type="search"]:focus,
input[type="number"]:focus,
input[type="tel"]:focus,
input[type="range"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="week"]:focus,
input[type="time"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="color"]:focus,
textarea:focus {
  width: 100%;
  margin: 0 0 15px;
  padding: 10px 0;
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  line-height: 20px;
  font-weight: inherit;
  color: #999;
  background-color: #f2f2f2;
  border: 1px solid #e8e8e8;
  border-radius: 0;
  outline: 0;
  box-sizing: border-box;
  transition: all ease 0.4s;
}*/

select {
  border: 1px solid #ccc;
}

textarea {
  width: 100%;
  height: 200px;
}

input[type="submit"] {
  display: inline-block;
  font-weight: 400;
  color: #FFF;
  text-align: center;
  vertical-align: middle;
  padding: 12px 28px;
  font-size: 15px;
  line-height: 1.5;
  border-radius: 0px;
  position: relative;
  z-index: 9;
  overflow: hidden;
  letter-spacing: 0.5px;
  transition: all 0.3s ease;
}

.stylish-input-group {
  position: relative;
  input {
    border-right: 0;
    box-shadow: 0 0 0;
    border-color: #ccc;
    float:right;
    max-width: 300px;
    margin:0;
  }

  .input-group-addon {
    padding: 6px 12px;
    font-size: 14px;
    font-weight: normal;
    line-height: 1;
    color: #555;
    text-align: center;
    border: none;
  }
  button {
    border: 0;
    background: transparent;
    position: absolute;
    right: 4px;
    top: 2px;
  }
}
