/*************************************************************************************

Breadcrumbs section

**************************************************************************************/

.breadcrumb {
  background-color: transparent;

  display: block !important;
}

.breadcrumb.transparent_header_breadcrumb {
  padding: 10px 15px;

}

.breadcrumb_wrapper {

}


.hapse-breadcrumb{
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #d8d8d8;
  @media (max-width: 736px) {
    flex-wrap: wrap;
  }
}
.breadcrumb h2 {
  color: #005884;
  font-size: 19px;
  font-weight: 700;
  margin: 0;
  line-height: 110%;
  text-transform: capitalize;
  display: table-cell;
  vertical-align: middle;
  font-family: $font_title;
}

.breadcrumb nav {
  display: table-cell;
  vertical-align: middle;
}

.breadcrumb ul {
  text-align: right;
  color: #005884;
  padding: 0;
  margin: 0;
}

.breadcrumb ul li {
  display: inline;
  font-size: 14px;
  color: #005884;
  font-weight: 500;
}

.breadcrumb ul li a {
  color: #005884;
  transition: 0.8s ease;
}

.breadcrumb ul li a:after {
  content: ">";
  color: #005884;
  padding: 0px 8px;
  position: relative;
  top: 1px;
}

.breadcrumb ul li:last-child a:after {
  content: "";

}
